.list-group.bg-grey {
    background-color: gainsboro;
    border-radius: 2px;
    .content, .options {
        display: inline-block;
    }
    .content {
        min-width: 150px;
        max-width: 550px;
    }
    .options {
        float: right;
        .badge {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}
