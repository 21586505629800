html,
body {
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth !important;
}
@font-face {
    font-family: 'Roboto Regular';
    src: url('fonts/Roboto-Regular.ttf');
}
@font-face {
    font-family: 'Roboto Bold';
    src: url('/fonts/Roboto-Bold.ttf');
}
@font-face {
    font-family: 'Roboto Light';
    src: url('/fonts/Roboto-Light.ttf');
}
@font-face {
    font-family: 'Roboto Condensed Regular';
    src: url('/fonts/RobotoCondensed-Regular.ttf');
}

body {
    background-color: #f9fbfc;
    font-family: 'Roboto Regular', 'Roboto Light';
    font-size: 18px;
    color: $black-davivienda-text;
}

.modal-open{
    .modal-backdrop {
       background-color: #030303; 
       opacity: 60%;
    }
}


.nav,
.pagination,
.carousel,
.panel-title a {
    cursor: pointer;
}

a {
    color: $blue;
}

.back-direction {
    transform: rotate(-90deg);
    width: 24px;
}

.size-img {
    width: 18px !important;
    height: auto !important;
    padding-bottom: 2px;
}

.size-img-menu {
    width: 45px;
    height: 45px;
}

.img-span {
    margin-right: 10px;
}

.up-direction {
    transform: rotate(90deg);
    width: 38px;
}

.down-direction {
    transform: rotate(-90deg);
    width: 38px;
}

.continue-direction {
    transform: rotate(90deg);
    width: 24px;
}

.continue-direction-red {
    transform: rotate(180deg);
    width: 18px;
}

a:hover {
    text-decoration: none;
}

button {
    background: none;
    padding: 0;
    border: none;
    &:focus {
        outline: none;
    }
}

p,
h1,
h2,
h3 {
    margin: 0;
}
h1{
    font-size: 34px !important;
}
h2{
    font-size: 22px !important;
}
h3{
    font-size: 18px !important;
}
h4{
    font-size: 16px !important;
}
h5{
    font-size: 14px !important;
}

.text--turq {
    color: $redDavivienda;
}

.text--blue {
    color: $black;
}

.text--inline {
    display: inline-block;
}

.text--smallest {
    font-size: 12px;
}

.wrapper {
    min-height: calc(100vh - 121px);
    // padding-bottom: 20px;
}

.logo {
    display: block;
    font-size: 0;
    text-indent: -9999px;
    background-size: cover;
    background-position: center;
    margin: 0;
}

.logo-susty {
    background-image: url("../img/logos/logo-susty.png");
    width: 129px;
    height: 38px;
}

.logo-davivienda {
    background-image: url("../img/logos/logo-davivienda-P.png");
    width: 288px;
    height: 28px;
}

.logo-davivienda-p {
    background-image: url("../img/logos/logo-davivienda.png");
    width: 288px;
    height: 28px;
}

.see-more-davi {
    background-image: url("../img/content/see-more.png");
    width: 18px;
    height: 28px;
}

.logo-susty-outline {
    background-image: url("../img/logos/logo-susty-outline.png");
    width: 44px;
    height: 38px;
}

//ERROR
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: $error;
}

// REGISTER
.login--register {
    .register {
        padding-top: unset;
        height: calc(100vh - 80px);
        max-width: none;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../img/content/fondo_login.png");
        display: flex;
        align-items: center;
       
        .card--login {
            margin: -7px auto;
            max-width: 430px;
            height: 26em;
            h1{
                margin: 0;
            }
        }
        .card {
            box-shadow: -15px 15px 31px 0px rgba(0,0,0,1);
            background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255, .8));
            border-radius: 20px;
            a {
                color: $black;
            }
        }
        input {
            background-color: transparent !important;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
        }
        .register__text__login {
            >h2 {
                &::before {
                    background-color: unset;
                }
                padding-top: 13px;
            }
            
        }
    }
}
.fondo--register {
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../img/content/fondo_login.png");
    
    .card__inner {
        padding: 0px 40px 20px;
    }
}

.addCalcRegister{
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
}

.register {
position: relative ;
    max-width: 80%;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    .size-img{
        width: 20px !important;
        margin-left:20px;
        padding-top: 10px;
        padding-bottom: 0;
    }
    .input {
        background-color: unset;
    }

    .card--register h2{
        margin: 23px 0;
    }


 
    .card{
          margin-bottom: unset;
          box-shadow: -15px 15px 31px 0px rgba(0,0,0,1);
            background: linear-gradient(rgba(255, 255, 255, .9), rgba(255, 255, 255,.9 ));
            border-radius: 20px;
    }
    .data__form{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    h1 {
        margin-bottom: 10px;
        color: $redDavivienda;
    }
    .logo {
        margin: 20px auto 40px;
    }
    .register__text__login {
        display: block;
        text-align: center;
        padding: 10px;
    }
    .register__text {
        display: block;
        text-align: center;
        padding: 10px;
    }
}

//
.certification-progress {
    position: relative;
    max-width: 620px;
    margin: 0 auto;
}

.advance {
    margin-top: 40px;
    ol {
        list-style-type: none;
        padding: 0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 68%;
            height: 3px;
            background-color: $gray-light;
            bottom: 7px;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    li {
        padding: 0 0 20px;
        width: 33.3333333%;
        float: left;
        text-align: center;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &::before {
            content: "";
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: $gray-light;
            border-radius: 50%;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .active {
        &::before {
            background-color: #fff;
            border: 3px solid $turq;
        }
    }
}

.progress__btns {
    text-align: center;
    button {
        /* background-color: $blue-dark; */
        border: none;
        padding: 0;
        color: #fff;
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        margin: 16px 8px;
    }
    @media screen and (min-width: 920px) {
        position: absolute;
        right: -145px;
        bottom: 0;
    }
}

// PAGE
.header--page {
    padding: 40px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .btn--back {
        display: inline-block;
        margin-right: 20px;
        border: none;
        vertical-align: top;
        .size-img {
            width: 26px !important;
            padding-bottom: 8px;
        }
    }
    .titles {
        display: inline-block;
        width: calc(100% - 60px);
        vertical-align: middle;
        // margin-left: 150px;
    }
    .titles-home {
        display: flex;
        justify-content: center;
        width: 100%;
        // margin-left: 150px;
    }
}

.title--page {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 5px;
}

.compound-title {
    .title--page,
    .title--page--right {
        display: inline-block;
        vertical-align: middle;
        width: 50%;
    }
    .title--page--right {
        text-align: right;
    }
    .btn--back {
        vertical-align: middle;
    }
}

.subtitle--page {
    font-size: 16px;
}

.production-line-header {
    display: inline-block;
    width: 100%;
    margin-bottom: 15px;
    .text-container {
        width: 70%;
        +.btn-container {
            width: 30%;
        }
    }
    .btn-container {
        text-align: right;
        button {
            padding: 8px;
        }
    }
    .btn-container,
    .text-container {
        display: inline-block;
        vertical-align: middle;
    }
}

// MODAL
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
}

// TAGS
.tag-container {
    margin: 20px 0;
}

.tag {
    display: inline-block;
    background-color: $blue;
    color: #fff;
    padding: 5px 10px;
    margin-right: 15px;
    button {
        padding: 0;
        border: none;
        background: none;
        font-size: 14px;
        margin-left: 15px;
        color: $black;
    }
}

// RADIO + CHECKBOX
.radio-desc {
    font-size: 14px;
    padding: 15px;
    border: 2px solid $blue;
    margin: 5px 0 20px;
}

.checkbox-desc {
    padding: 20px 35px;
}

// FLEXBOX
.d-flex {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.gra-flex {
    display: flex;
    align-items: center;
    gap: 10px;
    label {
        font-weight: unset;
    }
   
    .size-img{
        padding-bottom: unset;
    }
}
.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

//ROW
.r-flex {
    display: flex;
    align-items: baseline;
}

// INFO
.header--info {
    background-color: #fff;
    border-bottom: 1px solid $gray-light;
    padding-bottom: 50px;
    .container {
        max-width: 850px;
        margin: 0 auto;
        
          
        
    }
    .contenedor{
        width: 100%;
        img{
            object-fit: cover;
            height: 290px;
        }
    }
   
}

.header--info__text {
    font-size: 14px;
    &:first-child {
        margin-bottom: 10px;
    }
}

.header--info__title {
    h2 {
        font-size: 27px;
        display: inline-block;
        margin: 35px 0;
    }
    .report {
        color: $black;
        font-size: 15px;
        display: inline-block;
        margin-left: 30px;
    }
}

.certification-options {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    .btn-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 200px;
        padding: 5px;
        margin-right: 5px;
    }
    .btn--red {
        background-color: unset;
        box-shadow: 5px 10px 8px #888888;
    }
}

.btn__row {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.header--data__col {
    .row {
        display: flex;
        padding-left: 20%;
        .col-sm-6 {
            text-align: start;
        }
    }
}

.header--data__cer {
    display: flex;
    justify-content: center;
}

.header--info__row {
    display: flex;
    justify-content: center;
    .certification-title {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .certification-circle {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
    }
    .header--info__title--icon:nth-child(2) {
        padding-top: 15px;
    }
}

.header--info__title--icon {
    span {
        display: inline-block;
        vertical-align: middle;
        color: $black;
    }
    .glyphicon {
        color: $red;
    }
}

.card--tabs {
    .nav-tabs.nav-justified {
        background-color: #fff;
        max-width: 960px;
        margin: 0 auto 40px;
        padding: 20px 20px 0 20px;
        @include box-shadow(0,
        2px,
        4px,
        0,
        rgba(0,
        0,
        0,
        0.2));
        h2 {
            font-size: 22px;
            margin: 20px 0;
        }
        .card__subtitle {
            text-transform: uppercase;
        }
    }
    .nav-tabs.nav-justified>li>a {
        color: $gray;
        border: none;
        border-radius: 0;
        font-size: 16px;
        &:hover {
            background-color: #fff;
        }
    }
    .nav-tabs.nav-justified>.active>a {
        color: $black;
        border: none;
        border-bottom: 3px solid $redDavivienda;
        font-weight: 700;
    }
    .tab-content {
        background-color: #fff;
        max-width: 960px;
        margin: 40px auto;
        @include box-shadow(0,
        2px,
        4px,
        0,
        rgba(0,
        0,
        0,
        0.2));
    }
}

.header--tabs {
    background-color: unset;
    .nav-tabs.nav-justified {
        background-color: unset;
        max-width: 42%;
        margin: 0 auto;
       // position: relative;
       // padding-bottom: 10px;
        margin-top: -9px;
        li {
            width: 90px;
        }
        /*  left: 6%; */
    }
    .nav-tabs.nav-justified>li>a {
        color: $white;
        border: none;
        border-radius: 0;
        font-size: 16px;
        width: max-content;
        &:hover {
            background-color: unset;
        }
    }
    .nav-tabs.nav-justified>.active>a {
        color: $white;
        border: none;
        background-color: unset;
        border-bottom: 3px solid $white;
        font-weight: 700;
        width: max-content;
    }
    .tab-pane {
        background-color: #f9fbfc;
        padding-top: 10px;
    }
    .form-box {
        .tab-pane {
            background-color: white;
            padding: 0px;
        }
        .nav-tabs li {
            padding-bottom: 30px;
        }
    }
}

.tabs {
    .tab-pane {
        background-color: #fff;
    }
}

//PROGRESS
.file-upload-container {
    a {
        color: white;
    }
    img {
        height: 200px;
        width: 100%;
    }
    .tag {
        width: 100%;
        padding: 5px 10px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        button {
            position: absolute;
            right: 10px;
            top: 9px;
        }
    }
    /*.icon-close {
        position: absolute;
        right: 20px;
        top: 10px;
    }*/
    .progress {
        height: 32px;
    }
    .progress-bar {
        font-size: 18px;
        padding: 5px 10px;
    }
}

.progress {
    height: 5px;
    background-color: $blue-lightest;
    margin-bottom: 5px;
    overflow: visible;
}

.progress-bar {
    background-color: $blue;
}

.progress-circle {
    position: relative;
    width: 100px;
    float: left;
    margin-top: 20px;
    @media screen and (min-width: 768px) {
        margin: 0;
    }
}

.progress-circle__text {
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.progress-circle__info {
    float: left;
    width: 50%;
    margin-left: 5px;
    padding-top: 20px;
}

//COLLAPSE
.collapse,
.collapsing {
    .form-box:first-child {
        margin-top: 0;
    }
}

.panel {
    .collapse__top {
        padding: 0 40px;
        transition: all 0.5s;
    }
}

.panel-open {
    .collapse__top {
        padding: 20px 40px 0;
        transition: all 0.5s;
    }
}

.anuales .panel-body>div {
    display: flex;
    flex-wrap: wrap;
}

.anuales .panel-body>div.text-center {
    display: grid;
    justify-content: end;
    margin-right: 1%;
}

.content-rigth {
    display: grid;
    justify-content: end;
    margin-right: 1%;
}

.anuales .panel-body>div>div {
    width: calc(50% - 2rem);
    margin: 1rem;
    overflow: auto;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

// BTN BOX
.box-btn {
    background-color: $gray-lighter;
    padding: 15px 20px;
    margin-top: 20px;
    font-size: 14px;
    button {
        float: right;
        [class^="icon"] {
            display: inline-block;
            margin-left: 15px;
            font-size: 22px;
            vertical-align: middle;
        }
    }
}

//LISTS
.list--drag {
    padding: 0 20px 0 0;
    list-style-type: none;
    p {
        line-height: 24px;
    }
    [class^="fa"] {
        color: $gray;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }
    div {
        padding: 15px;
        border: 1px dashed $gray;
        margin-bottom: 10px;
        min-height: 56px;
    }
}

.list--drop {
    list-style-type: none;
    counter-reset: my-awesome-counter;
    padding-left: 25px;
    p {
        line-height: 24px;
    }
    [class^="fa"] {
        color: $gray;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
    }
    div {
        padding: 15px;
        border: 1px dashed $gray;
        margin-bottom: 10px;
        min-height: 56px;
        background-color: $gray-lighter;
    }
    li {
        counter-increment: my-awesome-counter;
        position: relative;
        &::before {
            content: counter(my-awesome-counter);
            position: absolute;
            left: -25px;
            top: 17px;
            color: $gray;
        }
    }
}

.list--faq {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px;
    li {
        margin-bottom: 25px;
        a {
            color: $blue;
        }
    }
}

//FAQ
.question {
    margin-bottom: 35px;
    p:first-child {
        font-weight: 700;
        margin-bottom: 10px;
    }
}

//MATERIALITY
.materiality {
    [class^="col-"]:first-child {
        border-right: 1px solid $gray;
    }
}

//POPOVER
.card__text>.popover {
    max-width: 524px;
    top: 98px!important;
    left: 145px!important;
}

.popover {
    border-radius: 0;
}

.popover--line {
    .popover-content {
        padding: 20px;
        .btn--white {
            display: block;
            color: $blue;
        }
        p {
            margin-bottom: 15px;
            font-size: 14px;
        }
    }
}

.popover--form {
    max-width: none;
    .popover-content {
        padding: 20px;
    }
    label {
        font-size: 14px;
        color: $gray;
    }
    .btn {
        margin: 0 auto;
        [class^="fa"] {
            color: #fff;
            font-size: 22px;
        }
    }
}

.popover--simple {
    p {
        font-size: 14px;
        margin: 0;
    }
    .popover-content {
        padding: 10px;
    }
}

@media screen and (min-width: 768px) {
    .text-right-desktop {
        text-align: right;
    }
}

// SPRITES
[class^="icon-multi-"] {
    display: inline-block;
    vertical-align: middle;
    background-image: url("../img/content/spritesheet.png");
    background-repeat: no-repeat;
    width: 55px;
    height: 50px;
}

.icon-multi-back {
    background-position: -140px 0;
}

.icon-multi-marker {
    background-position: -0 -51px;
}

.icon-multi-pad {
    background-position: 0 0;
}

.icon-multi-search {
    background-position: -67px 0;
}

.icon-multi-info {
    background-position: -186px 0;
}

.icon-multi-add {
    background-position: -124px -51px;
}

.icon-multi-cog {
    background-position: -62px -51px;
}

.icon-multi-building {
    background-position: -186px -51px;
}

.upload-file-note {
    display: block;
    font-size: 12px;
    font-weight: normal;
}

.m-0 {
    margin: 0;
}

.mt-2 {
    margin-top: 20px;
}

.switch {
    position: relative;
    top: 5px;
    display: inline-block;
    width: 30px;
    height: 17px;
    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked+.slider {
            background-color: $turq;
        }
        &:focus+.slider {
            box-shadow: 0 0 1px $turq;
        }
        &:checked+.slider:before {
            -webkit-transform: translateX(13px);
            -ms-transform: translateX(13px);
            transform: translateX(13px);
        }
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;
        &:before {
            position: absolute;
            content: "";
            height: 13px;
            width: 13px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 50%;
        }
    }
}

#toggle-evidencias {
    float: right;
    div {
        display: inline;
        padding: 5px;
    }
}

#evidencias {
    padding-bottom: 4em;
    overflow-y: auto !important;
}

.overflow-auto {
    overflow-y: auto !important;
}

.banner-semi-circle {
    height: 171px;
    border-bottom-left-radius: 61% 100%;
    border-bottom-right-radius: 61% 100%;
    width: 100%;
    background-image: url(https://www.gmmco.in/images/solar-energy-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 -275px;
    box-shadow: inset 0px -12px 5px 0px rgba(237, 28, 39, 0.53);
}



.card-swiper {
    @media only screen and (max-width: 766px) {

        margin-left: unset;
        margin-right: unset;  

        .header-certification {
            #filter-buttons{
                display: block !important;   
               }
           #init-module{
            display: none;
           }
            #init-buttons {
                display: none !important;
            }
            .buttons-filter{                        
                gap: unset !important;
                justify-content: unset !important;
                flex-direction: column;
            }
           .panel-title {
            margin-top: 10px;
            margin-bottom: 10px;
            text-align: center;
            
           }
           .panel-collapse>.panel-body{
            border-top-color: transparent;
           }
            .panel{
               
                width: 226px;
                border: 2px solid $redDavivienda;
                .panel-heading {
                    background-image: unset;
                    background-color: transparent;
                  
                }
                
            }
        }

    }
    .header-certification {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 30px;
        margin-right: 50px;
        margin-bottom: 20px;
        >div {
            width: 50%;
        }
        .buttons-filter {
            .d-flex{
                border: 2px solid $redDavivienda;
                border-radius: 4px;
            }

            display: flex;
            justify-content: flex-end;
            gap: 40px;
            .select2-choice{
                border: 2px solid transparent;
              
            }
        }
        #filter-buttons{
         display: none;   
        }
    }
   
    margin-left: 110px;
    margin-right: 110px;  
    .footer-model{
        display: flex;
        justify-content: center;
        .pagination {
            display: flex;
            align-items: center;
        }
        
        .pagination > li > a {
            background-color: unset ;
            border: unset;
            font-size: 13px;
            padding: 0px 10px
        }
        .pagination > .active > a {
            background-color: unset  ;
            color: $gray-davivienda ;
            border-bottom: solid $redDavivienda;
        }
    }
    .cards-module{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        align-content: stretch;
        gap: 4rem;
        .card-carusel {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;            
            transition: 0.3s;
            border-radius: 5px;
            width: 360px;

            .img-logo{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                position: relative;
                img {
                    max-height: 225px;
                }
            }
            .name-certification{
                background-color: $gray-davivienda;
                padding: 5px;
                >h5 {
                    color: $white;
                    text-align: center;
                    font-size: 18px !important;
                }
            }
            .header-card {
                border-radius:50%;
                width: 85px;
                height: 85px;
                background-color: $white;                   
                position: absolute;
                bottom: -15%;
                padding: 10px;
                
            }
        }
        img {
            border-radius: 9px 9px 0px 0px;
        }
        .container {
            width: 100%;
            padding-top: 15px;
            .progress {
                width: 35%;
                height: 7px;
                .progress-bar {
                    border-radius: 7px;
                }
            }
            .footer-card {
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
                .btn--round {
                    padding: 6px 12px 4px 10px;
                    margin-top: 8px;
                }
            }
        }
    }

 
}

.container-user {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .data-user {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 20%;
        >a {
            display: flex;
            justify-content: space-between;
            margin-right: 2rem;
            color: $black;
            >p {
                width: 210px;
            }
        }
        .size-img-menu {
            width: 24px;
            height: auto;
        }
    }
    .sub-data-option {
        background: #FFFFFF;
        box-shadow: 1px 4px 19px rgba(0, 0, 0, 0.25);
        border-radius: 9px;
        .margin-container {
            margin: 30px 72px 30px 30px;
            .aside__edit {
                position: relative;
                z-index: 0;
                bottom: -43px;
                left: -16px;
                cursor: pointer;
            }
            div:first-child {
                display: flex;
                justify-content: space-evenly;
                padding-bottom: 12px;
                /*  div:first-child { 
                    width: 100px;
                    height: 100px;
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    background: darkgrey;
                } */
            }
        }
    }
}

.contenedor {
    position: relative;
    display: inline-block;
    text-align: center;
    .centrado {
        color: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 20px;
        transform: translate(-50%, -50%);
        box-shadow: inset 10px 10px 120px 0px rgba(0, 0, 0, 0.95);
        box-shadow: inset 10px 10px 120px 40px rgba(109, 109, 113, 0.85);
        border-radius: 8px;
    }
}

.abstract--body {
    display: flex;
    margin-top: 50px;
    justify-content: center;
    gap: 3%;
    align-items: center;
    .content {
        padding: 12px;
        >div {
            font-size: 14px;
        }

       
    }
    .header--body{
        width: 400px;
    }
    
    .btn-options{
        background-color: $redDavivienda;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        border-radius: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
        span{
            color: $white !important;
        }
        
    }

    .info-options{
     //  margin-top: 20px;
       padding: 12px;
        span{
            color: $black !important;
        }
        
    }
    .abstract--certification {
        @include box-shadow(1px,
        4px,
        19px,
        0,
        rgba(0,
        0,
        0,
        0.25));
        border-radius: 9px;
        width: 100%;
      //  height: 185px;
        .header {
            background-color: $gray-davivienda;
            border-top-left-radius: 9px;
            border-top-right-radius: 9px;
            >p {
                padding-left: 12px;
                padding-top: 8px;
                padding-bottom: 8px;
                color: $white;
            }
        }
        .header--progress {
            padding-left: 12px;
            padding-right: 12px;
            padding-top: 10px;
            padding-bottom: 8px;
            width: 100%;
            .general{
                display: flex;
                justify-content: space-between;
                align-items: center;
                img{
                    cursor: pointer;
                }
            }
            .progress {
                height: 14px;
                width: 100%;
                border-radius: 20.125px;
                margin-top: 10px;
                .progress-bar {
                    border-radius: 20.125px;
                }
            }
        }
       
    }
}

certification-header>.header--info {
    border-bottom: unset;
}

.select--caculator{
    //width: 17rem;
    width: clamp(5rem,100%,17rem);
    .select2-choice{
        border: 2px solid #d5dbdd!important;
        filter: none;
        font-size: 15px;
        border-radius: 5px!important;
        background-image: none;
        height: 27px;
    }
    .select2-chosen > span{
        font-size: 15px;
    }
   .select2-choice .select2-arrow{
        background: transparent;
        border-left: none;
        filter: none;
    }
}

.content-new-btn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .display-padding{
        padding-left: 5rem;
        padding-right: 5rem;
    }
 
    
}
.footer-calculator{
    background: #f7f7f7;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.35);
    height: 6rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5rem;
    position: fixed;
    top: calc(100vh - 6.2rem);
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
}

.text-end{
text-align: end;
}