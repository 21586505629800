// GREN
.header--tabs .nav-tabs.nav-justified>.green>a {
	border-color: $green;
}

.green {
	.card__title {
		&::before {
			background-color: $green;
		}
		.card__icon {
			color: $green;
		}
	}
	[class^="fa"] {
		color: $green;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $green;
	    border-bottom: $green;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $green;
	      background-color: $green;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $green;
	}

	.radio:checked + label:before {
	  border: 2px solid $green;
	}

	.radio:checked + label:after {
	  background-color: $green;
	}
}


// BLUE
.header--tabs .nav-tabs.nav-justified>.blue>a {
	border-color: $blue;
}

.blue {
	.card__title {
		&::before {
			background-color: $blue;
		}
		.card__icon {
			color: $blue;
		}
	}
	[class^="fa"] {
		color: $blue;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $blue;
	    border-bottom: $blue;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $blue;
	      background-color: $blue;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $blue;
	}

	.radio:checked + label:before {
	  border: 2px solid $blue;
	}

	.radio:checked + label:after {
	  background-color: $blue;
	}
}


// PURPLE
.header--tabs .nav-tabs.nav-justified>.purple>a {
	border-color: $purple;
}

.purple {
	.card__title {
		&::before {
			background-color: $purple;
		}
		.card__icon {
			color: $purple;
		}
	}
	[class^="fa"] {
		color: $purple;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $purple;
	    border-bottom: $purple;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $purple;
	      background-color: $purple;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $purple;
	}

	.radio:checked + label:before {
	  border: 2px solid $purple;
	}

	.radio:checked + label:after {
	  background-color: $purple;
	}
}


// ORANGE
.header--tabs .nav-tabs.nav-justified>.orange>a {
	border-color: $orange;
}

.orange {
	.card__title {
		&::before {
			background-color: $orange;
		}
		.card__icon {
			color: $orange !important;
		}
	}
	[class^="fa"] {
		color: $orange !important;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $orange;
	    border-bottom: $orange;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $orange;
	      background-color: $orange;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $orange;
	}

	.radio:checked + label:before {
	  border: 2px solid $orange;
	}

	.radio:checked + label:after {
	  background-color: $orange;
	}
}


// BROWN
.header--tabs .nav-tabs.nav-justified>.brown>a {
	border-color: $brown;
}

.brown {
	.card__title {
		&::before {
			background-color: $brown;
		}
		.card__icon {
			color: $brown;
		}
	}
	[class^="fa"] {
		color: $brown;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $brown;
	    border-bottom: $brown;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $brown;
	      background-color: $brown;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $brown;
	}

	.radio:checked + label:before {
	  border: 2px solid $brown;
	}

	.radio:checked + label:after {
	  background-color: $brown;
	}
}


// RED
.header--tabs .nav-tabs.nav-justified>.red>a {
	border-color: $red;
}

.red {
	.card__title {
		&::before {
			background-color: $red;
		}
		.card__icon {
		 	color: $red;
		}
	}
	[class^="fa"] {
		color: $red;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $red;
	    border-bottom: $red;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $red;
	      background-color: $red;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $red;
	}

	.radio:checked + label:before {
	  border: 2px solid $red;
	}

	.radio:checked + label:after {
	  background-color: $red;
	}
}


// TURQUOISE
.header--tabs .nav-tabs.nav-justified>.turquesa>a {
	border-color: $turq;
}

.turquesa {
	.card__title {
		&::before {
			background-color: $turq;
		}
		.card__icon {
			color: $turq;
		}
	}
	[class^="fa"] {
		color: $turq;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $turq;
	    border-bottom: $turq;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $turq;
	      background-color: $turq;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $turq;
	}

	.radio:checked + label:before {
	  border: 2px solid $turq;
	}

	.radio:checked + label:after {
	  background-color: $turq;
	}
}


// MAGENTA
.header--tabs .nav-tabs.nav-justified>.magenta>a {
	border-color: $magenta;
}

.magenta {
	.card__title {
		&::before {
			background-color: $magenta;
		}
		.card__icon {
			color: $magenta;
		}
	}
	[class^="fa"] {
		color: $magenta;
	}
	.checkbox:checked {
	  + label:before {
	    border-right: $magenta;
	    border-bottom: $magenta;
	  }

	}
	.checkbox {
	  &:checked {
	    + label:before {
	      border-right: 2px solid #FFF;
	      border-bottom: 2px solid #FFF;
	    }

	    + label:after {
	      border: 2px solid $magenta;
	      background-color: $magenta;
	    }
	  }
	}
	.radio:checked + label:before,
	.radio:checked + label:after {
	  border-color: $magenta;
	}

	.radio:checked + label:before {
	  border: 2px solid $magenta;
	}

	.radio:checked + label:after {
	  background-color: $magenta;
	}
}

.indicator {
   /*  &.green {
        color: $green !important;
    }
    &.blue {
        color: $blue !important;
    }
    &.purple {
        color: $purple !important;
    }
    &.orange {
        color: $orange !important;
    }
    &.brown {
        color: $brown !important;
    }
    &.red {
        color: $red !important;
    }
    &.turquesa {
        color: $turq !important;
    }
    &.magenta {
        color: $magenta !important;
    } */
}