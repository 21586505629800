.modal.right {
    .modal-dialog {
    	position: fixed;
    	width: 100%;
    	height: 100%;
    	max-width: 545px;
    	right: 0;
        padding: 0px;
        margin: 0px;
        background-color: white;
        overflow: scroll;
        -webkit-transform: translate(25%, 0);
        -ms-transform: translate(25%, 0);
        -o-transform: translate(25%, 0);
        transform: translate(25%, 0);
    }
    &.in .modal-dialog {
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        -o-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .modal-content {
        border: none;
        box-shadow: none;
        
    }
    &.lg .modal-dialog{
        max-width: 640px;
    }
}