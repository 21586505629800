$black: #2C4C57;
$gray: #A7B5BA;
$gray-light: #D5DBDD;
$gray-lighter: #F8F8F8;
$gray-lightest: #FAFAFA;
$gray-dark: #9a9a9a;
$gray-darker: #939393;
$blue: #01AED9;
$blue-dark: #2C4C57;
$blue-lightest: #D8EBF8;
$turq: #0ADFB2;
$magenta: #E0117E;
$orange: #F58220;
$star-yellow: #FCD75B;
$pink: #E4007D;
$error: #F38230;
$green: green;
$purple: purple;
$brown: brown;
$red: red;
$white: white;
$greenH: #7BEED6;
//calc dashboard variables
$calc-main: #317EEF;
$calc-header:#7D7D7D;
$calc-dashboard:#f5f5f5;
$calc-info: #F8CC36;
$calc-inventory: #6D6D6D;
$calc-total: #66DBB6;
$calc-audition: #D88A47;
//Calc card variables
$calc-card-background: #F7F7F7;
$calc-card-edit: #7D7D7D;
$calc-card-remove: #EA5757;

//Davivienda color

$redDavivienda: #ED1C27;
$black-davivienda-text: #1d1d1b;
$red-davivienda-background: #ad0000;
$gray-davivienda: #4E4E4E;
$black-davivienda: #292929;
$white-cal-davivienda: #e5e5e5;