//calc-body-title



    select{
         border: 2px solid #d5dbdd !important;
         padding: 2px; 
         font-size: 15px;   
         border-radius: 5px !important;
    }
 calculator{
    input {
        font-size: 15px ;
    }
    textarea {
        font-size: 15px ;
    }
    .office-subtitle{
        font-size: 16px ;
    }
    .office-total-measurer-text{
        font-size: 16px ;
    }

}
   

.calc-subtitle-container {
    display: flex;
    align-items: center;
    margin-left: 4.75rem;
    margin-bottom: 3.625rem;
    margin-top: 3.125rem;
}
.btn-add-office {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
   
    width: 120px;
    padding-bottom: 4px;
    font-size: 10px;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    .size-img {
        width: 15px!important;
        height: auto!important;
         padding-bottom: unset; 
    }
}
.calc-subtitle {
    color: #000;
    border-left: 5px solid $redDavivienda;
    padding-left: 5px;
    display: flex;
   
}

.button-info {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border: none;
    background-color: $redDavivienda;
    border-radius: 20px;
    .size-img{
        width: 10px!important;
        height: auto!important;
         padding-bottom: unset;
    }
}

.icon-info {
    color: white;
    font-size: 15px;
}

//cards
.calc-card {
    display: flex;
    background: $calc-card-background;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 380px;
    height: 250px;
    border: 1px solid $gray-davivienda;
    border-top: none;
    border-radius: 2px;
}

.calc-card-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 12%;
    width: 100%;
    background: $gray-davivienda;
}

.head-title {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
    margin: 0;
    margin-left: 10px;
}

.calc-card-body {
    display: flex;    
    flex-direction: column;
    flex-wrap: wrap;
    gap: 17px;
    padding: 10px;
    height: 82%;
    width: 100%;
    .item-calc-card {
        display: flex;
        justify-content: flex-start;
        width: 100%
    }
    .no-data{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        height: 100%;
        justify-content: center;
        > img {
            height: 47px;
        }
    }
    

}

.name-input {
    width: 220px;
    height: 24px;
    border: 1px solid #A4A7A9;
    outline: none;
}

.code-input {
    width: 80px;
    height: 24px;
    margin-left: 10px;
    border: 1px solid #A4A7A9;
    outline: none;
}

.btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $calc-card-edit;
    height: 24px;
    width: 60px;
    border-radius: 40px;
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
    border: none;
}

.btn-remove {
    height: 24px;
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background: $redDavivienda;
    border: none;
    color: $white-cal-davivienda;
    font-size: 12px;
    font-weight: 500;
    margin-left: 10px;
}

.calc-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #DBDBDB;
    height: 20%;
    width: 95%;
    .pagination {
        display: flex;
        align-items: center;
    }
    
    .pagination > li > a {
        background-color: unset ;
        border: unset;
        font-size: 13px;
        padding: 0px 10px
    }
    .pagination > .active > a {
        background-color: unset  ;
        color: $gray-davivienda ;
        border-bottom: solid $redDavivienda;
    }
    .size-img {
        width: 13px!important;
        height: auto!important;
        padding-bottom: unset; 
    }
    .btn--red{
        display: flex;
        width: 190px !important;
        padding: 4px 4px 6px 4px;
        align-items: center;
       
    justify-content: center;
    gap: 10px;
        p{
            font-size: 11px;
        }
    }
}

.btn-add {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    border-radius: 40px;
    color: #fff;
    font-size: 11px;
    border: none;
}

//info
.calc-general-container {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(3, 1fr);
    /* Media query para pantallas medianas: 2 columnas */
@media (max-width: 1000px) {
  
        grid-template-columns: repeat(2,  1fr);
    
}

/* Media query para pantallas pequeñas: 1 columna */
@media (max-width: 700px) {
   
        grid-template-columns: repeat(1, 1fr);
    
}
    .col-general{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        p{
            height: 2.875rem;
        }
        textarea{
            width: 100%;
            height: 106px;
            max-height: 106px;
            outline: 0;
            resize: none;
        }
        input{
            width: 100%;
        }
    }
    input {
        border: 2px solid #d5dbdd !important;
       // padding: 4px 1px;    
        border-radius: 5px !important;
    }
    input:focus {
        border: 2px solid black !important;
    }
}

.general-input-group {
    display: flex;
    flex-direction: column;
   

}

.general-input-group-save {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
}

.general-input-subtitle {
    color: #000;
    font-size: medium;
    font-weight: 500;
}

.general-input {
    border: solid 1px #A4A7A9;
    border-radius: 2px;
    border-width: thin;
    outline: none;
    width: 250px;
    color: #000;
    font-weight: 300;
    font-size: 16px;
}

//

//inventory emissions


//emission register
.emission-container {
    color: #000;
    input {
        border: 2px solid #d5dbdd !important;
       // padding: 4px 1px;    
        border-radius: 5px !important;
    }
    input:focus {
        border: 2px solid black !important;
    }

    
    .subtitle {
       
        margin-bottom: 1.6rem;
        h4{
        font-size: 1.8rem !important;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
            margin: unset;
        }
    }

    .resume-data {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .tooltip-inner {
          max-width: 600px !important;
        }
        



        .m-auto {
            width: 100%;
            .electrical-subtitle-totals {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 1rem;
            }
        }
    }
    .emission-subtitle-data {
        font-size: 16px;
        font-weight: 400;
        max-width: 150px;
    }
    
    .emission-subtitle {
        font-size: 16px;
    }
    
    
    .line {
        height: 20px;
        margin-left: 10px;
        width: 90%;
        border-top: 2px solid #DDDDDD;
    }
  
    
    .evidence-subtitle {
        font-size: 16px;
    }
    .evidence-subtitle-check {
        font-size: 16px;
        margin-right: 4px;
    }
    .style-disabled {
        border: none;
        background-color: #DFE8EB;
    }
    .emission-input-edit {
        height: 25px;
        min-width: 202px;
        outline: none;
    }
   
    .emission-resume-container {
        .see-more{
            .open_list{
                background-color: $redDavivienda;
                width: 150px;
                border-radius: 1.3rem;
                color: #fff;
                font-size: 15px;
                border: none;
                padding: 2px;
            }
            display: flex;
            justify-content: flex-end;
             a{
                cursor: pointer;
                font-size: 16px;
            } 
        }
        table{
            width: 100%;
            .general-title{
             font-weight: bold;
            }
            background-color: white;
         }
        padding: 20px;
        background-color: #F7F7F7;
    }
    .resume {
        display: flex;
        width: 100%;
    }
    .emission-resume-total-container {
        padding: 20px;
        background-color: #F7F7F7;
        width: 100%;
        padding-left: 7.4rem;
       // margin-top: 20px;
        
    }
    .btn-add-office {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: $redDavivienda;
        width: 150px;
        border-radius: 1.3rem;
        color: $white;
        font-size: 16px;
        border: none;
        padding: 4px;
    }

    //Datos del electricidad
    .electrical-data-select {
        display: flex;
        gap: 20px;
      
        flex-wrap: wrap;
        .electrical-input {
            min-width: 156px;
            max-width: 190px;
            outline: none;
            height: -webkit-fill-available;
        }
    }
    .electrical-subtitle {
        font-size: 16px;
    }
    .electrical-input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 3px;
        gap: 3px;
        width: clamp(1rem,90%,17.9rem);
    }
    .electrical-line {
        height: 20px;
        border-top: 1px solid #777;
        width: 93%
    }
    .electrical-input-dark {
        background-color: #DFE8EB;
        width: 100%;
        outline: none;
        border: none;
        margin-bottom: 15px;
    }
    .electrical-months-data {
        display: flex;
        flex-direction: column;
    
        .months-top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            justify-content: flex-start;
            gap: 3rem;
        }
        .months-data {
            //display: flex;
            margin-bottom: 20px;
           // flex-wrap: wrap;
            display: grid;
            grid-template-columns: repeat(6,1fr);
            gap: 1rem;
            @media (max-width: 1250px) {

                grid-template-columns: repeat(4, 1fr);
    
            }
            .electrical-input {
             // max-width: 126px;
                outline: none;
                width: 100%;
            }
        }
        .months-subtitle-data {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 16px;
            font-weight: 500;
        }
    }
}

//total months
.months-total {
    padding: 20px;
    background-color: #F7F7F7;
    color: #000;
    font-size: 15px;
    div {
        justify-content: flex-start;
    }
    .top-container {
        input {
            border: 2px solid #d5dbdd !important;
           // padding: 4px 1px;    
            border-radius: 5px !important;
        }
        input:focus {
            border: 2px solid black !important;
        }
        display: flex;
        flex-direction: row;
        margin-bottom: 3rem;
    }
    .line {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 4rem;
    }
    .months-subtitle-data {
        display: flex;
        justify-content: flex-start;
        font-size: 18px;
        font-weight: 500;
        width: 100%;
    }
    .months-subtitle {
        font-size: 16px;
        font-weight: 400;
    }
    .months-input-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .months-input-dark {
        background-color: #DFE8EB;
        width: 100%;
        outline: none;
        border: none;
    }
    .months-input-total {
        background-color: #F7F7F7;
        border: none;
        border-bottom: 1px solid #cdcfd0;
        width: 100%;
        font-size: 15px;
        outline: none;
    }
    .months-icon-plus {
        margin-top: 2.6rem;
        color: #a5a8a9;
    }
    .months-line {
        height: 20px;
        border-top: 1px solid #777;
    }
    .months-buttons {
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .month-btn {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 15px;
        background-color: #7d7d7d;
        height: 28px;
        width: 170px;
        border-radius: 40px;
        color: #fff;
        font-size: 14px;
        border: none;
    }
}