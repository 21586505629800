.material-aspects {
    .description {
        margin-bottom: 15px;
    }
    .title {
        font-weight: bold;
        margin-top: 15px;
    }
    .options {
        margin-top: 15px;
    }
}