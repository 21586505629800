.card-equipment {
    width: clamp(1rem, 100%, 19.2rem);
    height: 17.2rem;
    flex-shrink: 0;
    border-radius: 1.1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    transition: all 0.3s ease;

    //  padding: 0 1rem;
    .title-month {
        color: #1E1E1E;

        font-size: clamp(1rem, 1.7rem, calc(1.7rem + 2vw)) !important;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25rem;
    }

    .container-card {
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        align-items: center;

        .select-equipment {
            width: clamp(1rem, 90%, 17.9rem);
            height: 3.02rem;
            min-width: unset;
            display: flex;

            .select--caculator {
                width: 100%;
                
                .select2-choice{
                    border: 2px solid rgb(213, 219, 221) !important;
                    border-radius: 5px !important;
                    height: 3.02rem;
                }
            }
            .btn {
                max-width: unset;
                min-width: unset;
            }

            .select--block {
                width: 100%;
            }

            .ui-select-bootstrap>.ui-select-match>.btn {
                margin: unset;
                height: 3.02rem;

            }

            .form-control .ui-select-search {
                background-color: red;
            }

        }

        .usage-month {
            width: clamp(1rem, 90%, 17.9rem);
            height: 3.02rem;
            flex-shrink: 0;

            >input {
                width: 100%;
                height: 100%;
            }
        }
    }


    .equipment-footer {
        display: flex;
        align-items: center;

        gap: 1rem;
        width: clamp(1rem, 90%, 17.9rem);

        .detail {
            width: clamp(1rem, 100%, 13.1rem);
        }

        .btn--blue {
            width: 100%;

            height: 3.02rem;
            flex-shrink: 0;
            border-radius: 0.5rem;

            color: #FFF;
            font-size: 1.7rem;
            font-style: normal;
            font-weight: 700;
        }

        .btn--check {
            width: clamp(3.229rem, 100%, 4.229rem);
            display: flex;


            #Ellipse_2 {
                fill: #919191;
                transition: all 0.3s ease;
            }
        }
    }
}

.focus-input {

    background: #e0e0e0;

    #Ellipse_2 {
        fill: #8DC466 !important;
    }

}

.calc-windows-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding-left: 2rem;
    padding-right: 2rem;
    gap: 4.4rem;
    width: 100%;

    .emission-repeat {
        display: grid;
        grid-template-columns: repeat(6, 1fr);

        @media (max-width: 1250px) {

            grid-template-columns: repeat(4, 1fr);

        }

        gap: 1rem;
    }

    .emission-item {
        background-color: #F7F7F7;
        padding: 1.8rem 4.1rem 4.4rem 3.5rem;
        border-radius: 0.4rem;
        width: 100%;
        >h3 {
            margin-bottom: 2.377rem;
            color: #1E1E1E;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    input {
        border: 2px solid #d5dbdd !important;
        height: 3.02rem;
        width: clamp(1rem, 100%, 17.9rem);
        border-radius: 5px !important;
    }

    input:focus {
        border: 2px solid black !important;
    }


}

.inventory-emissions-container {
    padding-left: 2rem;
    width: 100%;
    padding-right: 2rem;

    input {
        border: 0.123rem solid #919191 !important;
        width: clamp(5rem, 100%, 17rem);
        border-radius: 2px !important;
    }

    input:focus {
        border: 2px solid black !important;
    }

    .select-type {
        display: flex;
        align-items: center;

        padding: 10px;
        margin-bottom: 20px;
        color: #000;


        .select--caculator {
            border-radius: 11px !important;
            border: 2px solid #4F4F4F;
            width: clamp(2rem, 100%, 27.4rem);
            height: 4.1rem;
            flex-shrink: 0;

            .select2-choice {
                width: clamp(2rem, 100%, 27.4rem);
                border: unset !important;
                border-radius: 11px !important;
                background-color: white;
                height: 3.8rem;
                display: flex;
                align-items: center;
            }

            .select2-search input {
                width: 100% !important;
            }
        }

        h3 {
            margin-right: 3rem;
            color: #3A3A3A;
            font-size: 2.6rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        h4 {
            margin-right: 1.3rem;
            color: #3A3A3A;
            font-size: 1.9rem;
            font-weight: 700;
        }

        .btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 400px;
            padding: 2px;
            border-radius: 2px;
        }

        .btn-primary {
            width: 400px;
            font-weight: 300;
            background: $white;
            color: #000;
            border-color: #000;
        }

        .open>.dropdown-toggle.btn-primary:focus,
        .open>.dropdown-toggle.btn-primary:hover {
            background-color: #fff;
            color: #000;
            width: 400px;
        }

        .open>.dropdown-menu {
            display: block;
            width: 230px;
        }
    }

    .select-input {
        height: 25px;
        width: 30%;
        margin-left: 10px;
        outline: none;
    }

    .equipment {
        display: flex;
        flex-wrap: wrap;
        color: #000;
        gap: 0.3rem;

        >div {
            display: flex;
            gap: 1.2rem;
            width: 100%;
        }
    }

    .equipment-data {
        display: flex;
        padding: 1rem;
        justify-content: center;
        width: clamp(99.3rem, 100%, calc(100% - 7rem));

        min-height: 8.7rem;
        flex-direction: column;
        justify-content: center;
        background-color: #F7F7F7;

        .equipment-data-select {
            display: flex;
            width: 100%;
            align-items: center;
            gap: 1.6rem;
            flex-wrap: wrap;

            >div {
                width: clamp(5rem, 100%, 17rem);
            }

            .round_trip {
                display: flex;
                align-items: flex-start;
                gap: 8px;
            }

          

            .btn {
                min-width: 90px;
                max-width: 145px;
                background: #fff;
                color: #000;
                padding: 2px;
                border-color: #7D7D7D;
                font-weight: 400;
                font-size: 14px;
            }

            .open>.dropdown-toggle.btn-primary:focus,
            .open>.dropdown-toggle.btn-primary:hover {
                background-color: #fff;
                color: #000;
            }
        }
    }

    .select-input-equipment {
        height: 25px;
        //min-width: 80px;
        outline: none;
    }

    .select-input-equipment-disable {
        background-color: #D3D6D9;
        border: none;
        height: 25px;
    }

    .equipment-subtitle {
        font-size: 16px;
        margin-bottom: 1rem;
        width: max-content;

        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;

    }

    .equipment-total {
        width: 12%;
        background-color: #f7f7f7;
        display: flex;
        padding: 10px;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 13px;

        p {
            font-size: medium;
        }
    }

    .equipment-total-value {
        background-color: transparent;
        border: none !important;
        border-bottom: 1px solid !important;
        border-radius: unset !important;
        height: 25px;
        width: 100%;
        font-size: 12px;
        outline: none;
    }

    .btn-add-office {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: $redDavivienda;
        width: 150px;
        border-radius: 1.3rem;
        color: $white;
        font-size: 16px;
        padding: 4px;
        border: none;
    }

    .btn-delete {
        margin-right: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        height: 20px;
        width: 100px;
        border-radius: 40px;
        color: #fff;
        font-size: 10px;
        border: none;
        background-color: #9A9898;
    }

    .btn-office {
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 158px;
        border-radius: 1.3rem;
        color: #fff;
        font-size: 16px;
        border: none;
        background-color: $redDavivienda;
        padding: 4px;
    }

    .office {
        display: flex;
        color: #000;
        flex-direction: column;
        background-color: #F7F7F7;
        padding: 10px;
    }

    .office-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .office-data-row {
        display: flex;
        width: 80%;
    }

    .office-data-select {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(3, 1fr);
    }

    .office-input-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;

        p {
            font-size: 16px;
        }
    }

    .office-input {
        height: 25px;
        width: 130px;
        outline: none;
    }

    .office-input-direction {
        margin-left: 20px;
    }

    .office-input-country {
        max-width: 130px;
    }

    .office-total {
        display: flex;
        flex-direction: column;
        border-left: 2px solid #000;
        padding: 20px;
        justify-content: center;
        padding-right: 39px;
        width: 20%;
    }

    .direction-input {
        width: 282px;
        height: 114px;
        max-height: 200px;
        outline: none;
        resize: none;
    }

    .measurer {
        margin-top: 30px;
        padding: 10px;
        background-color: #e4e4e4;
        display: flex;
        justify-content: flex-start;
        gap: 10px;

        .office-total-measurer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: 1px solid #000;
            padding-left: 20px;
            margin-left: 20px;
        }
    }

    .btn-green {
        transition: .3s;
        height: 40px;
        width: 40px;
        position: relative;
        border-radius: 20px;
        background-color: #D3D6D9;
        border: solid 3px #7D7D7D;
        margin-left: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #7D7D7D;
    }

    .btn-green-selected {
        transition: .3s;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background-color: #8DC466;
        border: solid 3px #8DC466;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #fff;
    }
}

.btn-actions-new {
    width: clamp(1rem, 100%, 7rem);
    min-height: 8.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    flex-direction: column;
    gap: 1.5rem;

    img {
        width: clamp(1rem, 100%, 3.3rem);
    }
}

.layaut-emission {
    padding-left: 3rem;
    padding-right: 3rem;

    .header-month {
        display: flex;
        justify-content: space-between;
        margin-bottom: -1rem;
        .filter-month {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            position: relative;
            /* left: -2%; */
            top: -6.3rem;
        
            select {
                padding-left: 2.3rem;
                font-size: 2rem;
            }

            button {
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-align: start;
            }
        }
    }

    .emission-container {
      
        .title-data {
            color: #1E1E1E;
            font-size: 2.2rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
       
        .content-emission {
            .content-data {
                display: flex;
                margin-bottom: 4rem;
                background-color: #F7F7F7;
                padding: 2.4rem  0 2.4rem 7.4rem;
                .aerial{
                    width: clamp(1rem,100%,calc(100% - 7rem)) !important;
                    .emission-data{
                        flex-direction: column;
                      
                    }
                    .content-inputs-left{
                        width: 70%;
                    }
                    .content-inputs-rigth{
                        width: 30%;
                    }
                    .select--caculator {
                        width: 100%;
                        
                      
                    }
                }
                .outsourced{
                    
                 
                    .content-inputs-left{
                        width: 58%;
                        .content-2{
                            width: 65%;
                        }
                        .content-1{
                            width: 35%;
                        }
                    }
                    .content-inputs-rigth{
                        width: 42%;
                        .evidence-input{
                            height: 17.2rem !important;
                        }
                    }
                }
                .form-data {
                    width: 100%;
                  
                    display: flex;
                    gap: 3.5rem ;
                   .content-inputs-left{
                    display: flex;
                    gap: 1.7rem;
                    flex-direction: column;
                    .inventory{
                        display: flex;
                        gap: 2rem;
                        .inventory-rigth{
                            width: 35%;
                            .radio-group{
                                display: flex;
                                flex-direction: column;
                           //    height: 100%;
                                justify-content: space-between;
                                margin-top: 0.5rem;
                                
                                input[type="radio"] {
                                    --active: #275EFE;
                                    --active-inner: #fff;
                                    --focus: 2px rgba(39, 94, 254, .3);
                                    --border: #BBC1E1;
                                    --border-hover: #275EFE;
                                    --background: #fff;
                                    --disabled: #F6F8FF;
                                    --disabled-inner: #E1E6F9;
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    height: 21px;
                                    outline: none;
                                    display: inline-block;
                                    vertical-align: top;
                                    position: relative;
                                    margin: 0;
                                    cursor: pointer;
                                    border: 1px solid var(--bc, var(--border));
                                    background: var(--b, var(--background));
                                    transition: background .3s, border-color .3s, box-shadow .2s;
                    
                                    &:after {
                                        content: '';
                                        display: block;
                                        left: 0;
                                        top: 0;
                                        position: absolute;
                                        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
                                    }
                    
                                    &:checked {
                                        --b: var(--active);
                                        --bc: var(--active);
                                        --d-o: .3s;
                                        --d-t: .6s;
                                        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
                                    }
                    
                                    &:hover {
                                        &:not(:checked) {
                                            &:not(:disabled) {
                                                --bc: var(--border-hover);
                                            }
                                        }
                                    }
                    
                                    &:focus {
                                        box-shadow: 0 0 0 var(--focus);
                                    }
                    
                                    &:not(.switch) {
                                        width: 21px;
                    
                                        &:after {
                                            opacity: var(--o, 0);
                                        }
                    
                                        &:checked {
                                            --o: 1;
                                        }
                                    }
                    
                                    &+label {
                                        font-size: 14px;
                                        line-height: 21px;
                                        display: inline-block;
                                        vertical-align: top;
                                        cursor: pointer;
                                        margin-left: 4px;
                                    }
                                }
                    
                                input[type="radio"] {
                                    &:not(.switch) {
                                        border-radius: 7px;
                    
                                        &:after {
                                            width: 5px;
                                            height: 9px;
                                            border: 2px solid var(--active-inner);
                                            border-top: 0;
                                            border-left: 0;
                                            left: 7px;
                                            top: 4px;
                                            transform: rotate(var(--r, 20deg));
                                        }
                    
                                        &:checked {
                                            --r: 43deg;
                                        }
                                    }
                                }
                            }
                        }
                        .inventory-left{
                            width: 65%;
                            
                        }

                    }
                   
                    .header-inventory{
                        display: flex;
                        align-items: center;
                        gap: 2rem;
                        .content-2{
                            width: 100%;
                        }
                        .content-1{
                            width: 30%;
                        }
                        .dropdown{
                           
                               width: 100%;
                            }
                       
                    }
                    .emission-data {
                        display: flex;
                        gap: 2.4rem;
                        .evidence-subtitle {
                            max-width: 202px;
                            height: 30px;
                            text-align: center;
                        }
                        select{
                            width: clamp(17rem, 100%, 17rem+2rem) !important;
                        }
                        .emission-input {
                            height: 2.5rem;
                            width: clamp(5rem, 100%, 17rem);
                            outline: none;
                        }
                        .emission-input-group {
                            display: flex;
                            flex-direction: column;
                            gap: 0.7rem;
                           
                        }
                        
                    }
                    .evidence{
                        .waste{
                            width: 100% !important;
                        }
                    }
                   }
                  
                   .content-inputs-rigth{
                    display: flex;
                    gap: 1.7rem;
                    flex-direction: column;
                    .emission-data {
                        display: flex;
                        gap: 2.4rem;
                    }
                   }
                    .evidence-input-group {
                        display: flex;
                        flex-direction: column;
                        gap: 0.7rem;    
                        .input-aerial{
                         
                            width: 26.4rem;
                            height: 9.9rem;
                            outline: 0;
                            resize: none;
                        }       
                        .dropdown{
                            width: 100%;
                            .evidence-button-file {
                                border: none;
                                background-color: #EBEBEB;
                                height: 25px;
                                width: 100%;
                                font-size: 15px;
                                outline: none;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #8EB4ED;
                            }
                        }
                        .emission-input-check {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        .evidence-input {
                            border: none;
                            background-color: #DFE8EB;
                            width: 26.4rem;
                            height: 9.9rem;
                            outline: none;
            
                            resize: none;
            
                            
                        }
                    }
                   
                }
              
                .electrical{
                    flex-direction: column;
                    width: clamp(1rem,100%,calc(100% - 7rem));
                }
            }
            .farming{
                display: flex;
                flex-direction: column;
            }
            .emission-resume {
                padding-right: 2rem;
                padding-left: 4rem;
                flex-direction: column;
                .two-grid{
                    display: grid !important;
                    grid-template-columns: repeat(2, 1fr) !important;
                    select{
                        height: 3.8rem;
                        width: 100%;
                    }
                    
                }
              
               .text-end{
                margin-top: 3rem;
               }
               .form-data{
                flex-direction: column;
                .emission-data{
                    display: flex;
                    align-items: center;
                    gap: 2rem;

                }
                .resume-data{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 2.5rem;
                    select{
                        width: 100%;
                        height: 3.8rem;
                    }
                    @media (max-width: 1150px) {
            
                        grid-template-columns: repeat(2, 1fr);
            
                    }
                    .evidence-input{
                        height: 3.8rem;
                        width: unset;
                        background-color: #dfe8eb;
                    }
                }
               }
            }
       
           }
           .content-emission+.content-emission {
            margin-bottom: 4rem;
        }
    }

  

   
}
.certification-calculator{
    flex-direction: column;
    .certification-content{
        display: flex;
        .dashboard{
            width: 27.8rem;
            max-height: calc(100vh - 17.8rem);
            min-height: calc(100vh - 17.8rem);
            .menu{
                padding-left: 2rem;
            }
        }
        .container-display{
            width: calc(100% - 27.8rem);
            max-height: calc(100vh - 17.8rem);
            min-height: calc(100vh - 17.8rem);
            .calc-body{
                min-height: calc(100vh - 16.8rem);
                max-height: calc(100vh - 16.8rem);
            }
        }
    }
    
    
}