.loader-container {
    z-index: 9999;
    .modal {
        display: block;
    }
    .loader {
       // background-image: url(../img/logos/logo-susty-outline.png);
        background-image: url(../img/content/loader.png);
        background-repeat: no-repeat;
        position: absolute;
        top: calc(50% - 100px);
        left: calc(50% - 100px);
        background-position: center center; 
        border: 16px solid transparent;
        border-radius: 50%;
        width: 200px;
        height: 200px;
       // animation: spin 1s linear infinite;
    }
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    0% {border-top: 16px solid #3498db;}
    33% { border-right: 16px solid green;}
    66% { border-bottom: 16px solid yellow;}
    100% { border-left: 16px solid red; }
    
}

.spinner {
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    background-position: center center; 
    border: 16px solid transparent;
    border-radius: 50%;
    width: 200px;
    height: 200px;

    background-color: transparent;

    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: .8s;
    animation-name: spinner-loading;
 }
@keyframes spinner-loading{
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
    0% {border-top: 16px solid red;}
    33% { border-right: 16px solid white;}
    66% { border-bottom: 16px solid red;}
    100% { border-left: 16px solid white; }
}