.calc-container {
    padding: unset !important;
    margin-bottom: 5px;
    .row {
        width: 100%;
        height: 100%;
        margin: unset ;
        display: flex;
        .col-lg-9 {
            padding: 0;
        }
    }
}

.calc-header {
    display: flex;
    background: $white;
    color: $black;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 5px;
    margin: 0;
    border-bottom: solid;
    align-items: center;

    .content-flex {
        display: flex;
                justify-content: space-between;
                align-items: center;margin: unset;
    }

    .content-flex-false {
        justify-content:flex-end;
    }
    

    .size-img {
        width: 29px!important;
        padding: unset;
        margin: 0 1rem;
    }
    box-shadow: 0px -6px 7px 1px rgba(0,0,0,0.3);
    -webkit-box-shadow: 0px -6px 7px 1px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px -6px 7px 1px rgba(0,0,0,0.3);
    
    .title {
        padding-right: 2%;
        border-right: 10px solid $gray-davivienda;
        margin-right: 2%;
    }
    .subtitle {
        color: $black;
    }
}

.dashboard {
    background: $calc-dashboard;
    color: #000;
    min-height: calc(100vh - 8.8rem);
    max-height: calc(100vh - 8.8rem);
    z-index: 3;
    padding: unset;
    width: 25.8rem;
    min-width: 25.8rem;
    position: relative;
   // overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    .menu {
        display: flex;
        flex-direction: column;
       position: static;
        color: black;
        .panel-group{
            margin-bottom: 1.5rem;
        }
        uib-accordion {
            background-color: unset;
        }
        h3{
                     
            height: 5.6rem;
            flex-shrink: 0;
            background: #C8C8C8;
            padding: 0 0 0 2rem;
            display: flex;
            align-items: center;
             margin-bottom: 1.5rem;
        }
       
        .accordion {
            a,
            a:hover,
            a:active,
            a:visited,
            a:focus {
                text-decoration: none;
                outline: 0;
            }
            .panel-body {
                padding: 0;
                border-style: none;
                margin-top: 0.8rem;
            }
            .panel-heading {
                text-decoration-color: none;
                box-shadow: unset;
            }
            .info-style {
                margin-bottom: 1rem;
                border-left: 0.7rem solid $calc-info;
                padding-left: 0.5rem;
                background-color: $calc-dashboard;
            }
            .info-style-selected {
                background-color: $calc-info;
                color: #fff;
                padding-left: 0.5rem;
            }
            .emision-style {
                text-decoration: none;
                 margin-bottom: 1.5rem;
                background-color: unset;
                box-shadow: unset;  
                &>h4{
                    padding: 0 0 0 2rem;

                }

                .dropdown-menu{
                    top: 0;
                    left: 25.8rem;
                    padding: unset;
                    margin: unset;
                    border: unset;
                    box-shadow: unset;
                    min-width: 23.125rem;
                }
                 li {
                    list-style-type: none;
                    &:hover{
                        background-color: #0082C4;
                        >a{
                            color: #fff;
                        }
                       
                    }
                    .emision-item {
                        border-radius: 0.9rem;
                        font-size: 1.6rem;
                        // margin: 3px;
                        padding-left: 0.8rem;
                        margin-left: 3rem;
                       // background-color: #e5e5e5;
                        color: #2c4c57;
                        display: block;
                        padding: 0.2rem 1rem 0.2rem 0.8rem;
                       
                        width: max-content;
                    }
                }          
            }             
        }
        h4 {
            font-size: 2rem;
            font-weight: 400;
            //padding: 0 0 0 2rem;
            margin: auto;
        }
        
        a {
            font-size: 1.7rem;
            color: #000;
           //  margin-bottom: 1.5rem;
        }
        

        .color-menu {
            border-left: 0.8rem solid  $gray-davivienda;
            padding-left: 0.5rem;
            padding: 0.3rem 0.8rem 0.3rem 0.8rem;
            display: block;
           
        }

        .color-menu-selected {
            background-color: $gray-davivienda !important;
            color: $white !important;
            border-radius: 0.9rem 0 0 0.9rem;
           
        }
        #btn-append-to{
            width: 100%;
            display: block;
            text-align: start;
          > h4{
            padding: 0 0 0 2rem;

          }

        }

        .dropdown-menu-color-title{
            background-color: $gray-davivienda !important;
            color: transparent !important;
            
            padding: 0.3rem 0.8rem 0.3rem 0.8rem;
            display: block;
        }
        .container-li{
            background-color: #D9D9D9;
            margin-left: 0.5rem;
        }

        .total-syle {
            border-left: 0.7rem solid $redDavivienda;
            padding-left: 0.5rem;
           
        }
        .total-style-selected {
            background-color: $redDavivienda;
            color: $white !important;
            border-radius: 0.9rem;
            padding: 0.3rem 0.8rem 0.3rem 0.8rem;
        }
        
    
    }
}

.container-display{
    width: calc(100% - 25.8rem);
    min-height: calc(100vh - 8.8rem);
    max-height: calc(100vh - 8.8rem);
}
.calc-body {

    min-height: calc(100vh - 14.8rem);
    max-height: calc(100vh - 14.8rem);
  
    .months-years{
        width: 100%;
        position: relative;
        .title-question{
            padding: 5rem 1.5rem;
            font-size: 1.8rem !important;
        }
    }

    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        display: none;
    }
}