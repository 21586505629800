.form-indicator, .monthly-form {
    .page-info {
        float: left;
        padding-left: 8px;
        font-size: 14px;
        font-weight: 400; 
    }
    
}
.form-box .indicator {
   
   .gra-flex{
       width: 22%;
   }
}
.form-indicator {
    .form-box {
         max-height: 450px;
        overflow: auto;
        margin-bottom: 0px; 
        margin-top: 8px;
        .gra-flex{
            width: 22%;
        }
    }
    .btn--red {
        min-width: 100px;
        padding: 6px 12px;
        .fa-plus-circle {
           
            color: $white;
        }
    }
    .table {
      margin: 0;
    } 
    .loading {
        text-align: center;
    }
}

.monthly-form {
    .img-dropdown{
        height: 20px;
    } 
    .table-container {
        max-height: 844px;
        overflow: auto;  
    }
    .table-container::-webkit-scrollbar {
        display: none;
         width: 0 !important;
         
     }

    table .loading {
        text-align: center;
    }
    .btn--red {
        min-width: 100px;
        padding: 6px 12px;
    }

    .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3{
            margin-top: unset;
        }
        .icon{
            width: 50%;
        }
    }
}
