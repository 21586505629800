.table-wrapper {
    overflow: scroll;
}

.table--inventory,
.table--item-list {
    margin-top: 30px;
    font-size: 14px;
    color: $gray-darker;

    .size-img{
        width: 14px !important;
    }

    &>tbody>tr>td,
    &>tfoot>tr>td {
        border: none;
        vertical-align: middle;
    }
    tr:nth-child(odd) {
        background-color: $gray-lighter;
    }
    .fa-trash-o {
        font-size: 16px;
        color: $gray-darker;
    }
    .fa-chevron-circle-right {
        color: $turq;
        font-size: 26px;
    }
    .fa-plus-circle {
        font-size: 22px;
        color: #01AED9;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }
    tbody {
        td {
            width: 6%;
            text-align: center;
        }
        td:first-child {
            width: 88%;
            text-align: left;
        }
    }
    tfoot {
        tr:nth-child(odd) {
            background-color: #fff;
        }
    }
}

.table--item-list {
    margin: 0px;
}

.table--data {
    width: 100%;
    font-size: 14px;
    border: 1px solid $gray-light;
    border-top: none;
    margin-bottom: 30px;
    th {
        width: 5%;
        background-color: $gray-lighter;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 0;
        font-size: 11px;
        border-right: 1px solid $gray-light;
    }
    th,
    td {
        width: 5%;
        padding: 5px;
        text-align: center;
        border-bottom: 1px solid $gray-light;
        &:last-child {
            font-weight: bold;
            font-size: 0.9em;
            width: 9%;
            text-transform: none;
        }
        &:first-child {
            width: 20%;
            padding-left: 15px;
            text-align: left;
        }
        &:nth-child(even) {
            background-color: $gray-lighter;
        }
    }
    td {
        padding: 20px 5px;
        >input {
            border: 2px solid $gray-light;
            padding: 4px 1px;
            text-align: center;
            font-size: 1.1rem;
            border-radius: 5px;
            width: 100%;
            &:disabled {
                border: 2px solid $gray-lighter;
                background-color: $gray-lighter;
            }
            &:read-only {
                border: none;
                background-color: unset;
            }
        }
        >input.ng-valid.ng-not-empty.ng-dirty {
            border: 2px solid $gray-light;
            padding: 4px 1px;
            text-align: center;
            font-size: 1.1rem;
            border-radius: 5px;
            width: 100%;
            &:disabled {
                border: 2px solid $gray-lighter;
                background-color: $gray-lighter;
            }
        }
        button {
            clear: both;
            padding: 0;
            margin: 0em;
            font-size: 2rem;
            color: $redDavivienda;
        }
        .dropdown-menu {
            min-width: 175px;
            a {
                padding: 0.5em;
                cursor: pointer;
                &:hover {
                    background-color: $gray;
                }
            }
        }
    }
}

.table--graph {
    width: 100%;
    th {
        background-color: $gray-lighter;
        text-transform: uppercase;
        overflow: hidden;
    }
    th,
    td {
        font-size: 12px;
        padding: 5px;
        text-align: center;
        border: 1px solid $gray-light;
        &:nth-child(even) {
            background-color: $gray-lighter;
        }
    }
}

.table--data-average {
    width: 100%;
    font-size: 14px;
    margin-bottom: 30px;
    td {
        border: 1px solid $gray-light;
        padding: 20px;
        width: 60%;
        input {
            border: none;
            border-bottom: 1px solid $gray-light;
            width: 100%;
        }
        &:first-child {
            width: 40%;
        }
    }
}

.table--report {
    width: 100%;
    font-size: 14px;
    margin: 30px 0;
    th {
        font-weight: 500;
    }
    th,
    td:nth-child(even) {
        background-color: $gray-lighter;
    }
    th,
    td {
        border: 1px solid $gray-light;
        text-align: center;
        padding: 5px;
        width: 20%;
    }
}

.monthly-container {

    .content__chart_block {
        display: none !important;
    }
    .content__chart_active {
        display: block !important;
    }
    .title {
        padding-bottom: 10px;
       
        display: flex;
        align-items: center;
        justify-content: space-between;
       
        [class^="fa"] {
            color: $redDavivienda;
        }
       
    }
    .table--data {
        margin-bottom: 0;
        margin: 10px -16px;
        width: calc(100% + 32px);
        border: 0;
        th:first-child {
            overflow: visible;
        }
        .select--inline {
            padding: 0px;
            margin: 0px;
            margin-left: 5%;
        }
        .select2-choice {
            min-width: 100px;
            border: 1px solid #aaa;
        }
    }
    .table--data-average {
        margin-top: 30px;
        margin-bottom: 0px;
    }
    .footnote {
        font-size: 14px;
        margin-bottom: 30px;
    }
    margin-bottom: 30px;
}

.large-content {
    padding: 0 3em;
    @media (max-width: 1000px) {
        padding: 0;
    }
}