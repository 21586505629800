.btn {
    font-weight: 700;
    min-width: 150px;
    max-width: 230px;
    margin: 0 auto;
    border-radius: 2px;
    padding: 12px;
    outline: none;
    &+& {
        margin-left: 25px;
    }
    &:focus {
        color: inherit;
        outline: none;
    }
}

.btn--round {
    display: inline-block;
    // padding: 9px 7px 7px 13px;
    // padding: 10px 9px 8px 14px;
    padding: 10px 16px 8px 14px;
    border-radius: 5px;
    /* padding: 1px 3px 0px 4px;
	border-radius: 50%; */
    &.btn--turq {
        background-color: $turq;
        color: #FFF;
    }
    &:hover {
        text-decoration: none;
    }
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
        span {
            padding-left: 0.7rem;
        }
    }
}
#hubspot-conversations-inline-iframe {
    width: 393px;
    height: 315px;
    border: none;
    }
.helper {
    border-radius: 50%;
    background: linear-gradient(241deg, rgba(255,155,155,1) 0%, rgba(237,28,39,1) 70%);
    color: #fff;
    width: 65px;
    height: 65px;
    padding: 8px;
    position: fixed;
    box-shadow: inset 0 0 0 0 #6d6d6d;
    bottom: 1rem;
    right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-out .1s;
    animation-name: latir;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    .iconH-SH_Centro-de-ayuda::before {
        color: #FFF;
        font-size: 2em;
    }
}

.helper:hover {
    box-shadow: inset 65px 0 0 0 $gray-davivienda;
}

@keyframes latir {
    0% {
        transform: scale(.8);
    }
    20% {
        transform: scale(.8);
    }
    40% {
        transform: scale(1);
    }
    60% {
        transform: scale(.8);
    }
    80% {
        transform: scale(1);
    }
    100% {
        transform: scale(.8);
    }
}

.start-btn>button {
    min-width: 189px;
}

.btn--block {
    display: block;
    width: 45%;
    margin: 0 auto;
    &+& {
        margin: 10px auto;
    }
}

.btn-red {
    color: $redDavivienda;
}
.btn--helper{
    background-color: transparent;
    color: #2c4c57;
    border: 1.5px solid #7beed6;
    border-radius: 4px;
    height: 4.5rem;
    width: 10rem;
}
.icon {
    cursor: pointer;
    .size-img{
        width: 24px;
        padding-bottom: 8px;
    }
}

.btn--icon {
    font-size: 14px;
    font-weight: 400;
    padding: 3px 10px;
    border: 1px solid 46a8ed;
    margin: 0px;
    width: initial;
    min-width: inherit;
    [class^="fa"] {
        margin: 0 5px;
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        color: #01AED9;
    }
}

.btn--blue {
    background-color: $blue;
    color: #FFF;
    @include box-shadow(0, 2px, 4px, 0, rgba(0, 0, 0, 0.2));
}

.btn--red {
    background-color: $redDavivienda;
    color: #FFF;
    border-radius: 8px !important;    
    padding: 7px 14px 7px !important;
    font-size: 16px !important;
    width: 200px !important;
    height: 40px !important;
    &:hover {
        background-color: $red-davivienda-background;
        color: $white;
    }
    &:focus {
        background-color: $red-davivienda-background;
        color: $white;
    }    
    -webkit-box-shadow: inset 0 -4px 0 0 $red-davivienda-background;
    -moz-box-shadow: inset 0 -4px 0 0 $red-davivienda-background;
    box-shadow: inset 0 -4px 0 0 $red-davivienda-background;
    a{
        color: #fff;
    }
}

.btn--turq {
    color: #FFF;
    background-color: $turq;
}
.btn--gray {
    color: #FFF;
    border-radius: 10px;
    background: #828282;
    padding: 5px 24px;
    font-size: 2rem;
    color: #FFF;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.btn--yellow {
    color: #FFF;
    border-radius: 10px;
    background: #F8991D;
    padding: 5px 24px;
    font-size: 2rem;
    color: #FFF;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.btn--border-blue {
    background-color: #FFF;
    color: $blue;
    border: 2px solid $blue;
}
.btn--border-red{
    background-color: #FFF;
    color: $gray-davivienda;
    border: 2px solid $redDavivienda;
    border-radius: 10px;
}

.btn--border-turq {
    background-color: #FFF;
    color: $turq;
}

.btn--border-gray {
    background-color: #FFF;
    color: $gray;
    border: 2px solid $gray;
}

.btn--white {
    background-color: #FFF;
    color: #979797;
}

.btn--add {
    border: none;
    background: none;
    padding: 0;
    [class^="fa"] {
        font-size: 27px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 7px;
        color: $blue;
    }
}

.btn--info {
    border: none;
    background: none;
    padding: 0;
    margin-left: 7px;
    [class^="fa"] {
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
    }
}

.btn--back {
    border-radius: 50%;
    border: none;
    background: none;
    padding: 0;
    border: 2px solid $turq;
    width: 32px;
    height: 32px;
    line-height: 18px;
    text-align: center;
}

.btn--fb {
    border: 1px solid $redDavivienda;
    text-transform: uppercase;
    font-weight: 400;
    color: $black;
    [class^="fa"] {
        display: inline-block;
        font-size: 22px;
        margin-left: 10px;
        vertical-align: sub;
    }
}

.btn--start-module {
    background-color: transparent;
    color: $black;
    border: 2px solid $redDavivienda;
    width: 100%;
    margin-left: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    .fa-plus {
        margin-right: 10px;
    }
}

.btn--report {
    margin-left: -10px;
    width: 110%;
}