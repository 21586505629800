@mixin box-shadow( $horizontal: 10px , $vertical: 10px , $blur: 0px , $size: 0px , $color: rgba(0,0,0,0.1) ) {
    -webkit-box-shadow: $horizontal $vertical $blur $size $color;
 	-moz-box-shadow: $horizontal $vertical $blur $size $color;
 	box-shadow: $horizontal $vertical $blur $size $color;
}

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.clearfix:after {
    clear: both;
}
