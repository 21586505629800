.graph-container {
    svg {
        border: 1px solid #D5DBDD;
        margin-bottom: 15px;
    }
    .nvd3 g.nv-groups path.nv-line {
        stroke-width: 4px;
    }

    .nvd3 .nv-axis line {
        stroke-dasharray: 2, 2;
    }
    .nvd3 .nv-axis.nv-x path.domain {
        stroke-width: 1px;
        stroke-opacity: 1;
        stroke: #D5DBDD;
    }
    .nvd3 .nv-axis path.domain {
        stroke-width: 1px;
        stroke-opacity: 1;
        stroke: #D5DBDD;
    }
    .nvd3 text {
        font: 100 12px Roboto-Regular;
        text-transform: uppercase;
    }
}